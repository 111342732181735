

.app-header {
  height: 50px;
  background: #fff;
  padding: 0 20px;

  border-bottom: 1px solid #eaeaea;

  .info-wrap {
    overflow: hidden;

    .inner {
      transform: translate(35px);

      transition: transform 0.4s ease-in-out;
    }

    &:hover {
      .inner {
        transform: translate(0);
      }
    }
  }

  .avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background-size: cover;
    background-color: #f4f4f4;
  }

  .btn-logout {
    margin-left: 10px;
  }
}

