.page-settings .tabs-wrap {
  padding: 40px 60px;
  max-width: 1000px;
  margin: 40px auto;
  background-color: #f4f4f4;
}
.page-settings .tabs-wrap .title {
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
}
.page-settings .tabs-wrap .ant-tabs-nav {
  margin: 0;
}
.page-settings .tabs-wrap .ant-tabs-content-holder {
  background-color: #fff;
  padding: 30px;
}
.page-settings .tabs-wrap .coder-list .item {
  position: relative;
  display: flex;
  padding: 10px;
  margin-top: 20px;
  background-color: #f8f8f8;
}
.page-settings .tabs-wrap .coder-list .item .blk1 {
  width: 100px;
}
.page-settings .tabs-wrap .coder-list .item .icon-del {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 14px;
  top: 7px;
  font-size: 18px;
  cursor: pointer;
}
