/* 通配符修改重设内外边距 */
@import '~antd/dist/antd.css';
*,
p {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
/* body全局字体设置 */
body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #333;
  line-height: 1.6;
  font-size: 14px;
  min-width: 1008px;
}
/* 去除元素的focus状态时的边线 */
input,
textarea,
button,
a {
  outline: 0;
}
/* 清除列表的默认样式 */
ul,
ol {
  padding-left: 0;
  list-style-type: none;
}
li {
  list-style: none;
}
/* 将部分元素初始化为块级元素 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
/* 去除默认斜体样式*/
i {
  font-style: unset;
}
/* 去除a链接下划线 */
a {
  color: currentColor;
  text-decoration: none !important;
}
a:hover {
  color: currentColor;
}
/* 清除表单Input输入框以及textarea的浏览器默认样式 */
input[type=text],
input[type=password],
input[type=number],
input[type=tel],
input[type=email],
textarea {
  -webkit-appearance: none;
}
/* 修改部分元素的盒模型计算方式 */
html,
body,
div,
article,
aside,
section,
main,
nav,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
blockquote,
figcaption,
figure,
textarea,
table,
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  box-sizing: border-box;
}
/* 相对定位 */
.relative {
  position: relative;
}
/* 固定定位 */
.fixed {
  position: fixed;
}
/* 绝对定位 */
.absolute {
  position: absolute;
}
/* 绝对定位占满空间 */
.absolute-fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* 转换为块级元素 */
.db {
  display: block;
}
/* 转换为内联块级元素 */
.dib {
  display: inline-block;
}
/* 转换为flex伸缩盒 */
.flex {
  display: flex;
  display: -webkit-flex;
}
/* 多行flex */
.flex-wrap {
  flex-flow: wrap;
}
/* 横向flex */
.flex-row {
  flex-direction: row;
}
/* 纵向flex */
.flex-column {
  flex-direction: column;
}
/* flex拉伸 */
.flex-grow-1 {
  flex-grow: 1;
}
/* flex压缩 */
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
/* flex垂直居中对齐 */
.items-center {
  align-items: center;
  -webkit-align-items: center;
}
/* flex居中对齐 */
.justify-center {
  justify-content: center;
  -webkit-justify-content: center;
}
/* flex分散对齐 */
.justify-between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
/* flex右侧对齐 */
.justify-end {
  justify-content: flex-end;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.pointer {
  cursor: pointer;
}
.white {
  color: white;
}
.bold {
  font-weight: bold;
}
.tl {
  text-align: left;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.w-0 {
  width: 0;
}
.h-0 {
  height: 0;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.m-h-100 {
  min-height: 100%;
}
.m-auto {
  margin: 0 auto;
}
.no-bar::-webkit-scrollbar {
  display: none;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-999 {
  z-index: 999;
}
.z-9999 {
  z-index: 9999;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.bg-white {
  background-color: #fff;
}
.lh-lg {
  line-height: 2;
}
.lh-huge {
  line-height: 3;
}
.br-2 {
  border-radius: 2px;
}
.br-3 {
  border-radius: 3px;
}
.br-4 {
  border-radius: 4px;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
/* 省略号 */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.border-box {
  box-sizing: border-box;
}
.select-none {
  user-select: none;
}
.p-event-none {
  pointer-events: none;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: block;
  clear: both;
}
.multi-line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
}
.multi-line-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
}
html,
body,
#__next {
  height: 100%;
}
* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/
}
* ::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  height: 6px;
  right: 6px;
  background-color: transparent;
}
* ::-webkit-scrollbar-button {
  display: none;
}
* ::-webkit-scrollbar-track {
  display: none;
}
* ::-webkit-scrollbar-track-piece {
  display: none;
}
* ::-webkit-scrollbar-corner {
  display: none;
}
* ::-webkit-resizer {
  display: none;
}
* ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
}
.text-black-emphasize {
  color: #000;
}
.text-black-title {
  color: #000;
  font-weight: bold;
}
.text-black-body {
  color: rgba(0, 0, 0, 0.64);
}
.text-black-secondary {
  color: rgba(0, 0, 0, 0.4);
}
.text-black-disable {
  color: rgba(0, 0, 0, 0.24);
}
.text-white-emphasize {
  color: #fff;
}
.text-white-title {
  color: #fff;
  font-weight: bold;
}
.text-white-body {
  color: rgba(255, 255, 255, 0.8);
}
.text-white-secondary {
  color: rgba(255, 255, 255, 0.64);
}
.text-white-disable {
  color: rgba(255, 255, 255, 0.4);
}
.text-primary,
.text-primary-emphasize {
  color: #0c8cfa;
}
.text-primary-title {
  color: #0c8cfa;
  font-weight: bold;
}
.text-primary-light,
.text-primary-disable {
  color: #81c3fc;
}
.text-primary-lighter {
  color: #f3f8ff;
}
.text-primary-dark,
.text-primary-deeper {
  color: #0054c2;
}
.text-success,
.text-success-emphasize {
  color: #09c262;
}
.text-success-title {
  color: #09c262;
  font-weight: bold;
}
.text-success-light,
.text-success-disable {
  color: #7edfad;
}
.text-success-lighter {
  color: #f3fff9;
}
.text-success-dark,
.text-success-deeper {
  color: #008a2a;
}
.text-error,
.text-error-emphasize {
  color: #fa433c;
}
.text-error-title {
  color: #fa433c;
  font-weight: bold;
}
.text-error-light,
.text-error-disable {
  color: #fc9c99;
}
.text-error-lighter {
  color: #fff8f7;
}
.text-error-dark,
.text-error-deeper {
  color: #c20b04;
}
.text-warning,
.text-warning-emphasize {
  color: #ffbf00;
}
.text-warning-title {
  color: #ffbf00;
  font-weight: bold;
}
.text-warning-light,
.text-warning-disable {
  color: #ffdd7a;
}
.text-warning-lighter {
  color: #fffbef;
}
.text-warning-dark,
.text-warning-deeper {
  color: #c78700;
}
.text-info {
  color: #0eb1ef;
}
.text-black {
  color: #333;
}
.text-deep-gray {
  color: #666;
}
.text-gray {
  color: #999;
}
.text-light-gray {
  color: #cecece;
}
.text-navbar {
  color: #292c31;
}
.text-divide-line {
  color: #eee;
}
.text-background {
  color: #f4f4f4;
}
.text-cyan {
  color: #00B0BB;
}
.text-green {
  color: #00910A;
}
.text-purple {
  color: #6152CF;
}
.text-red {
  color: #FF1F00;
}
.text-blue {
  color: #0064FF;
}
.text-azure {
  color: #169AE5;
}
.text-orange {
  color: #FF8100;
}
.text-pink {
  color: #DE1E7C;
}
.text-violet {
  color: #C738D9;
}
.fs-base {
  font-size: 14px;
}
.fs-small {
  font-size: 12px;
}
.fs-large {
  font-size: 16px;
}
.fs-larger {
  font-size: 20px;
}
.fs-huge {
  font-size: 40px;
}
.bg-primary {
  background-color: #0c8cfa;
}
.bg-primary-light {
  background-color: #81c3fc;
}
.bg-primary-lighter {
  background-color: #f3f8ff;
}
.bg-primary-dark {
  background-color: #0054c2;
}
.bg-success {
  background-color: #09c262;
}
.bg-success-light {
  background-color: #7edfad;
}
.bg-success-lighter {
  background-color: #f3fff9;
}
.bg-success-dark {
  background-color: #008a2a;
}
.bg-error {
  background-color: #fa433c;
}
.bg-error-light {
  background-color: #fc9c99;
}
.bg-error-lighter {
  background-color: #fff8f7;
}
.bg-error-dark {
  background-color: #c20b04;
}
.bg-warning {
  background-color: #ffbf00;
}
.bg-warning-light {
  background-color: #ffdd7a;
}
.bg-warning-lighter {
  background-color: #fffbef;
}
.bg-warning-dark {
  background-color: #c78700;
}
.bg-black {
  background-color: #333;
}
.bg-deep-gray {
  background-color: #666;
}
.bg-gray {
  background-color: #999;
}
.bg-light-gray {
  background-color: #cecece;
}
.bg-lighter-gray {
  background-color: #e8e8e8;
}
.bg-black-emphasize {
  background-color: #000;
}
.bg-black-body {
  background-color: rgba(0, 0, 0, 0.64);
}
.bg-black-secondary {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-black-disable {
  background-color: rgba(0, 0, 0, 0.24);
}
.bg-white-emphasize {
  background-color: #fff;
}
.bg-white-body {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-white-secondary {
  background-color: rgba(255, 255, 255, 0.64);
}
.bg-white-disable {
  background-color: rgba(255, 255, 255, 0.4);
}
.bg-navbar {
  background-color: #292c31;
}
.bg-divide-line {
  background-color: #eee;
}
.bg-background {
  background-color: #f4f4f4;
}
.bg-cyan {
  background-color: #00B0BB;
}
.bg-green {
  background-color: #00910A;
}
.bg-purple {
  background-color: #6152CF;
}
.bg-red {
  background-color: #FF1F00;
}
.bg-blue {
  background-color: #0064FF;
}
.bg-azure {
  background-color: #169AE5;
}
.bg-orange {
  background-color: #FF8100;
}
.bg-pink {
  background-color: #DE1E7C;
}
.bg-violet {
  background-color: #C738D9;
}
.bg-g-primary {
  background: linear-gradient(45deg, #37ad9d, #37de7a);
}
.bg-g-success {
  background: linear-gradient(45deg, #f5a227, #ff4500);
}
.bg-g-accent {
  background: linear-gradient(45deg, #ff5f98, #ff563b);
}
.bg-g-info {
  background: linear-gradient(45deg, #0ed5ef, #72a4ff);
}
.bg-transparent {
  background-color: transparent;
}
.lh-sm {
  line-height: 1.4;
}
.lh-md {
  line-height: 1.8;
}
.lh-lg {
  line-height: 2.2;
}
*.bg-white {
  background: #fff;
}
*.bg-light-white {
  background: #fafafa;
}
.b-e8 {
  border: 1px solid #e8e8e8;
}
.hide {
  display: none;
}
