/* 通配符修改重设内外边距 */
*, p {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

/* body全局字体设置 */
body {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #333;
  line-height: 1.6;
  font-size: 14px;
  min-width: 1008px;
}

/* 去除元素的focus状态时的边线 */
input, textarea, button, a {
  outline: 0;
}

/* 清除列表的默认样式 */
ul, ol {
  padding-left: 0;
  list-style-type: none;
}

li {
  list-style: none;
}

/* 将部分元素初始化为块级元素 */
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

/* 去除默认斜体样式*/
i {
  font-style: unset;
}

/* 去除a链接下划线 */
a {
  color: currentColor;
  text-decoration: none !important;
  &:hover {
    color: currentColor;
  }
}

/* 清除表单Input输入框以及textarea的浏览器默认样式 */
input[type=text], input[type=password], input[type=number], input[type=tel], input[type=email], textarea {
  -webkit-appearance: none;
}

/* 修改部分元素的盒模型计算方式 */
html, body, div, article, aside, section, main, nav, footer, header, form,
fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt,
dd, blockquote, figcaption, figure, textarea, table, td, th, tr,
input[type="email"], input[type="number"], input[type="password"],
input[type="tel"], input[type="text"], input[type="url"] {
  box-sizing: border-box;
}




/* 相对定位 */
.relative {
  position: relative;
}

/* 固定定位 */
.fixed {
  position: fixed;
}

/* 绝对定位 */
.absolute {
  position: absolute;
}

/* 绝对定位占满空间 */
.absolute-fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* 转换为块级元素 */
.db {
  display: block;
}

/* 转换为内联块级元素 */
.dib {
  display: inline-block;
}

/* 转换为flex伸缩盒 */
.flex {
  display: flex;
  display: -webkit-flex;
}

/* 多行flex */
.flex-wrap {
  flex-flow: wrap;
}

/* 横向flex */
.flex-row {
  flex-direction: row;
}

/* 纵向flex */
.flex-column {
  flex-direction: column;
}

/* flex拉伸 */
.flex-grow-1 {
  flex-grow: 1;
}

/* flex压缩 */
.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/* flex垂直居中对齐 */
.items-center {
  align-items: center;
  -webkit-align-items: center;
}

/* flex居中对齐 */
.justify-center {
  justify-content: center;
  -webkit-justify-content: center;
}

/* flex分散对齐 */
.justify-between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

/* flex右侧对齐 */
.justify-end {
  justify-content: flex-end;
}

// 字体大小
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

// 鼠标手状态
.pointer {
  cursor: pointer;
}

// 白色
.white {
  color: white;
}

.bold {
  font-weight: bold;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.m-h-100 {
  min-height: 100%;
}

.m-auto {
  margin: 0 auto;
}

.no-bar::-webkit-scrollbar {
  display:none
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-white {
  background-color: #fff;
}

.lh-lg {
  line-height: 2;
}

.lh-huge {
  line-height: 3;
}

.br-2 {
  border-radius: 2px;
}
.br-3 {
  border-radius: 3px;
}
.br-4 {
  border-radius: 4px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* 省略号 */
.ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

// 修改盒模型计算方式
.border-box {
  box-sizing: border-box;
}

// 无法选取文字
.select-none {
  user-select: none;
}

// 禁用事件
.p-event-none {
  pointer-events: none;
}

// 清除浮动
.clearfix:after, .clearfix:before {
  content: "";
  display: block;
  clear: both;
}

// 两行截断
.multi-line-2 {
  overflow : hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
}

// 三行截断
.multi-line-3 {
  overflow : hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
}

html, body, #__next {
  height: 100%;
}

* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  //&:hover {
  //  &::-webkit-scrollbar-thumb {
  //    background-color: rgba(0, 0, 0, 0.3);
  //  }
  //}

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    position: absolute;
    width: 6px;
    height: 6px;
    right: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    display: none;
    //-webkit-box-shadow: inset 0 0 6px transparent;
    //border-radius: 10px;
    //background-color: transparent;
    //border:none;
  }

  ::-webkit-scrollbar-track-piece {
    display: none;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  ::-webkit-resizer {
    display: none;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0, 0, 0, 0.3);
  }
}


//  ----------------------- 变量 [start] ------------------
// ################### 配色
// 主色 Primary Colors
@text-color             : #333;
@primary-color          : #0c8cfa;
@primary-color-light    : #81c3fc;
@primary-color-lighter  : #f3f8ff;
@primary-color-dark     : #0054c2;

@info-color             : #2db7f5;

@success-color          : #09c262;
@success-color-light    : #7edfad;
@success-color-lighter  : #f3fff9;
@success-color-dark     : #008a2a;

@error-color            : #fa433c;
@error-color-light      : #fc9c99;
@error-color-lighter    : #fff8f7;
@error-color-dark       : #c20b04;

@warning-color          : #ffbf00;
@warning-color-light    : #ffdd7a;
@warning-color-lighter  : #fffbef;
@warning-color-dark     : #c78700;

// 中性色 Neutral Colors
@color-black            : #333;
@color-deep-gray        : #666;
@color-gray             : #999;
@color-light-gray       : #cecece;
@color-lighter-gray     : #e8e8e8;

// 灰阶透明度 Grayscale Transparency [主要针对文字]
@color-black-emphasize  : #000;
@color-black-body       : rgba(0, 0, 0, 0.64);
@color-black-secondary  : rgba(0, 0, 0, 0.4);
@color-black-disable    : rgba(0, 0, 0, 0.24);

@color-white-emphasize  : #fff;
@color-white-body       : rgba(255, 255, 255, 0.8);
@color-white-secondary  : rgba(255, 255, 255, 0.64);
@color-white-disable    : rgba(255, 255, 255, 0.4);

// 其它 Others
// nav-bar divider background
@color-nav-bar          : #292c31;
@color-divider-line     : #eee;
@color-bg               : #f4f4f4;

// [仅可用于图表、标签撞他，不可用于其它类型的界面]
// 青色
@color-cyan             : #00B0BB;
// 绿色
@color-green            : #00910A;
// 紫色
@color-purple           : #6152CF;
// 红色
@color-red              : #FF1F00;
// 蓝色
@color-blue             : #0064FF;
// 天蓝色
@color-azure            : #169AE5;
// 橙色
@color-orange           : #FF8100;
// 粉色
@color-pink             : #DE1E7C;
// 紫罗兰色
@color-violet           : #C738D9;

// ################### 字体
// 大小
@font-size-small        : 12px;
@font-size-base         : 14px;
@font-size-large        : 16px;
@font-size-larger       : 20px;
@font-size-huge         : 40px;

// 行高
@line-height-small      : 1.4;
@line-height-base       : 1.8;
@line-height-large      : 2.2;

// ################### 排版
// 圆角
@border-radius-base     : 2px;
@border-radius-large    : 4px;

// 高度 [btn/input]
@height-base        : 32px;
@height-small       : 28px;
@height-large       : 40px;

// 内边距 [btn/input]
@padding-base       : 12px;

//  ----------------------- 变量 [end] ------------------


// 配色
// ################### 字体
// 黑色透明梯度
.text-black-emphasize {
  color: @color-black-emphasize;
}
.text-black-title {
  .text-black-emphasize;
  font-weight: bold;
}
.text-black-body {
  color: @color-black-body;
}
.text-black-secondary {
  color: @color-black-secondary;
}
.text-black-disable {
  color: @color-black-disable;
}
// 白色透明梯度
.text-white-emphasize {
  color: @color-white-emphasize;
}
.text-white-title {
  .text-white-emphasize;
  font-weight: bold;
}
.text-white-body {
  color: @color-white-body;
}
.text-white-secondary {
  color: @color-white-secondary;
}
.text-white-disable {
  color: @color-white-disable;
}
// 基本颜色
.text-primary, .text-primary-emphasize {
  color: @primary-color;
}
.text-primary-title {
  .text-primary-emphasize;
  font-weight: bold;
}
.text-primary-light, .text-primary-disable {
  color: @primary-color-light;
}
.text-primary-lighter {
  color: @primary-color-lighter;
}
.text-primary-dark, .text-primary-deeper {
  color: @primary-color-dark;
}

.text-success, .text-success-emphasize {
  color: @success-color;
}
.text-success-title {
  .text-success-emphasize;
  font-weight: bold;
}
.text-success-light, .text-success-disable {
  color: @success-color-light;
}
.text-success-lighter {
  color: @success-color-lighter;
}
.text-success-dark, .text-success-deeper {
  color: @success-color-dark;
}

.text-error, .text-error-emphasize {
  color: @error-color;
}
.text-error-title {
  .text-error-emphasize;
  font-weight: bold;
}
.text-error-light, .text-error-disable {
  color: @error-color-light;
}
.text-error-lighter {
  color: @error-color-lighter;
}
.text-error-dark, .text-error-deeper {
  color: @error-color-dark;
}

.text-warning, .text-warning-emphasize {
  color: @warning-color;
}
.text-warning-title {
  .text-warning-emphasize;
  font-weight: bold;
}
.text-warning-light, .text-warning-disable {
  color: @warning-color-light;
}
.text-warning-lighter {
  color: @warning-color-lighter;
}
.text-warning-dark, .text-warning-deeper {
  color: @warning-color-dark;
}

.text-info {
  color: #0eb1ef;
}

.text-black {
  color: @color-black;
}
.text-deep-gray {
  color: @color-deep-gray;
}
.text-gray {
  color: @color-gray;
}
.text-light-gray {
  color: @color-light-gray;
}

.text-navbar {
  color: @color-nav-bar;
}
.text-divide-line {
  color: @color-divider-line;
}
.text-background {
  color: @color-bg;
}

.text-cyan {
  color: @color-cyan;
}
.text-green {
  color: @color-green;
}
.text-purple {
  color: @color-purple;
}
.text-red {
  color: @color-red;
}
.text-blue {
  color: @color-blue;
}
.text-azure {
  color: @color-azure;
}
.text-orange {
  color: @color-orange;
}
.text-pink {
  color: @color-pink;
}
.text-violet {
  color: @color-violet;
}

// 大小
.fs-base {
  font-size: @font-size-base;
}
.fs-small {
  font-size: @font-size-small;
}
.fs-large {
  font-size: @font-size-large;
}
.fs-larger {
  font-size: @font-size-larger;
}
.fs-huge {
  font-size: @font-size-huge;
}

// 背景色
.bg-primary {
  background-color: @primary-color;
}
.bg-primary-light {
  background-color: @primary-color-light;
}
.bg-primary-lighter {
  background-color: @primary-color-lighter;
}
.bg-primary-dark {
  background-color: @primary-color-dark;
}

.bg-success {
  background-color: @success-color;
}
.bg-success-light {
  background-color: @success-color-light;
}
.bg-success-lighter {
  background-color: @success-color-lighter;
}
.bg-success-dark {
  background-color: @success-color-dark;
}

.bg-error {
  background-color: @error-color;
}
.bg-error-light {
  background-color: @error-color-light;
}
.bg-error-lighter {
  background-color: @error-color-lighter;
}
.bg-error-dark {
  background-color: @error-color-dark;
}

.bg-warning {
  background-color: @warning-color;
}
.bg-warning-light {
  background-color: @warning-color-light;
}
.bg-warning-lighter {
  background-color: @warning-color-lighter;
}
.bg-warning-dark {
  background-color: @warning-color-dark;
}

.bg-black {
  background-color: @color-black;
}
.bg-deep-gray {
  background-color: @color-deep-gray;
}
.bg-gray {
  background-color: @color-gray;
}
.bg-light-gray {
  background-color: @color-light-gray;
}
.bg-lighter-gray {
  background-color: @color-lighter-gray;
}

.bg-black-emphasize {
  background-color: @color-black-emphasize;
}
.bg-black-body {
  background-color: @color-black-body;
}
.bg-black-secondary {
  background-color: @color-black-secondary;
}
.bg-black-disable {
  background-color: @color-black-disable;
}

.bg-white-emphasize {
  background-color: @color-white-emphasize;
}
.bg-white-body {
  background-color: @color-white-body;
}
.bg-white-secondary {
  background-color: @color-white-secondary;
}
.bg-white-disable {
  background-color: @color-white-disable;
}

.bg-navbar {
  background-color: @color-nav-bar;
}
.bg-divide-line {
  background-color: @color-divider-line;
}
.bg-background {
  background-color: @color-bg;
}

.bg-cyan {
  background-color: @color-cyan;
}
.bg-green {
  background-color: @color-green;
}
.bg-purple {
  background-color: @color-purple;
}
.bg-red {
  background-color: @color-red;
}
.bg-blue {
  background-color: @color-blue;
}
.bg-azure {
  background-color: @color-azure;
}
.bg-orange {
  background-color: @color-orange;
}
.bg-pink {
  background-color: @color-pink;
}
.bg-violet {
  background-color: @color-violet;
}

.bg-g-primary {
  background: linear-gradient(45deg, #37ad9d, #37de7a);
}

.bg-g-success {
  background: linear-gradient(45deg, #f5a227, #ff4500);
}

.bg-g-accent {
  background: linear-gradient(45deg, #ff5f98, #ff563b);
}

.bg-g-info {
  background: linear-gradient(45deg, #0ed5ef, #72a4ff);
}

.bg-transparent {
  background-color: transparent;
}

// 行高
.lh-sm {
  line-height: @line-height-small;
}
.lh-md {
  line-height: @line-height-base;
}
.lh-lg {
  line-height: @line-height-large;
}


*.bg-white {
  background: #fff;
}

*.bg-light-white {
  background: #fafafa;
}

.b-e8 {
  border: 1px solid #e8e8e8;
}

.hide {
  display: none;
}

@import '~antd/dist/antd.css';

