.page-login {

  .login-panel-wrap {
    width: 520px;

    //height: 400px;
    padding-bottom: 30px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);

  }
  .tab-wrap {
    height: 70px;
    line-height: 60px;

    color: #555;

    border-bottom: 1px solid #ddd;

    .title {
      //padding: 15px;
      height: 70px;
      padding-left: 20px;
    }
    .logo {
      display: block;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      background-image: url('https://code-sprite.oss-cn-shanghai.aliyuncs.com/wx-app/Icon.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .tab {

      .anticon {
        margin-right: 5px;
      }
      &:nth-child(2) {
        border-left: 1px solid #ddd;
      }
      &.active {
        border-bottom: 3px solid #0c8cfa;

        color: #0c8cfa;
      }
    }
  }

  .login_form {
    max-width: 96%;
    /*margin: 0 auto;*/
    padding: 40px;
  }
  .nav-link {
    margin-right: 20px;
  }

  .login-panel-worker {
    padding-top: 30px;
    //.title {
    //  padding: 15px;
    //}
    //.logo {
    //  display: block;
    //  margin-right: 15px;
    //  width: 50px;
    //  height: 50px;
    //  background-image: url('https://code-sprite.oss-cn-shanghai.aliyuncs.com/wx-app/Icon.png');
    //  background-repeat: no-repeat;
    //  background-size: 100%;
    //
    //}

    .wx-login-wrap {
      width: 300px;
      height: 300px;
      margin-left: 50px;

      //background-image: url('../../assets/imgs/ercode.png');
      background-size: 230px;
      background-position: 5px;
      background-repeat: no-repeat;

      box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);

      overflow: hidden;

      iframe {
        transform: translate(0, -17px);
      }
    }

    .wx-logo {
      width: 100px;
      height: 100px;

      background-image: url("../../assets/imgs/wx.svg");

      background-size: 100%;

      background-repeat: no-repeat;
    }
  }
}