.page-settings {

  .tabs-wrap {
    padding: 40px 60px;
    max-width: 1000px;

    margin: 40px auto;

    background-color: #f4f4f4;

    .title {
      line-height: 60px;
      font-size: 18px;
      font-weight: bold;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      background-color: #fff;
      padding: 30px;
    }

    .coder-list {
      .item {
        position: relative;
        display: flex;
        padding: 10px;

        margin-top: 20px;

        background-color: #f8f8f8;

        .blk1 {
          width: 100px;
        }
        .icon-del {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 14px;
          top: 7px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

  }
}

